import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import MainButton from "../components/main-button"
import { Link } from "gatsby"
import { motion } from "framer-motion"

const Container = styled.div`
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
`
const Logo = styled(motion.img)`
  width: 35%;
  margin-top: -60px;
`
const TextContainer = styled(motion.div)``
const Title = styled.h1`
  font-size: 9vh;
`
const Copy = styled.p`
  line-height: 150%;
  max-width: 600px;
  margin: 0 auto;
  color: #484848;
  margin-bottom: 50px;
`

class BookingConfirmationPage extends Component {
  state = {
    loading: true,
  }
  render() {
    return (
      <Layout max={true}>
        <Container>
          <Logo
            // initial={{ opacity: 0 }}
            // animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            src={require("../images/0.png")}
          />
          <TextContainer
            initial={{ opacity: 0, y: 20 }}
            transition={{ duration: 1 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <Title>See you soon.</Title>
            <Copy>
              Your booking was placed successfully. We can’t wait to host you
              and your guests! A confirmation email should be on its way to your
              inbox and we will reach out to you with a call soon.
            </Copy>
            <Link to="/">
              <MainButton title="Take me home" />
            </Link>
          </TextContainer>
        </Container>
        {/* <SummaryContainer></SummaryContainer> */}
      </Layout>
    )
  }
}

export default BookingConfirmationPage
